import workflowEvent from '../../../workFlow/workflowEvent';
import DatePicker from 'vue2-datepicker';
import addInternalWorkflow from '../../../onlineApproval/internalWorkFlow/addInternalWorkflow';
import CreditNote from '../../../oracle/arcreatecn/searchCn';
import HistoryRecord from '../../../onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '../../../onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '../../../onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '../../../onlineApproval/internalWorkFlow/history/sendForApproval';
import addEditMasterAgreement from '../../../leaseAdmin/lease/leases/masterAgreement/addEditMasterAgreement';
import addEditTenantLease from '../../../leaseAdmin/lease/leases/tenantLease/addEditTenantLease';
import addEditInvestorLease from '../../../leaseAdmin/lease/leases/investorLease/addEditInvestorLease';
import leaseTenantSchedule from '../../../leaseAdmin/lease/schedules/tenantSchedule/tenantDetails';
import leaseInvestorSchedule from '../../../leaseAdmin/lease/schedules/investorSchedule/investorDetails';
import purReqEntryForm from '../../../onlineApproval/purchaseRequisition/purReqEntryForm';
import addProvisionFile from '../../../provisionsAdmin/uploadProvisions/addProvisionFile';
export default {
  name: 'notificationDetails',
  components: {
    workflowEvent,
    DatePicker,
    addInternalWorkflow,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    CreditNote,
    addEditMasterAgreement,
    addEditTenantLease,
    addEditInvestorLease,
    leaseTenantSchedule,
    leaseInvestorSchedule,
    purReqEntryForm,
    addProvisionFile
  },
  watch: {
    selectAllCheckBox: function () {
      this.checkUncheckAll();
    }
  },
  data() {
    return {
      leaseHdrDetails: null,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      notificationComments: null,
      isChecked: false,
      selectAllCheckBox: false,
      selectedCheckBoxIndexArray: [],
      notificationSummaryDetail: {
        status: null,
        eventId: null
      },
      eventId: null,
      previewFlag: false,
      wf_inprocess_dtl_id: null,
      wfHeaderId: null,
      selectedGridData: null,
      showAddIntWorkflow: false,
      showHistory: false,
      historyType: null,
      selectedStatus: null,
      showDetailsModal: false,
      loader: false,
      eventName: null,
      eventDetailByName: null,
      searchForm: {
        eventName: null,
        eventId: null,
        fromDate: null,
        endDate: null
      },
      fromDate: null,
      endDate: null,
      showEventsModal: false,
      notificationDetailsList: [],
      notificationFields: [],
      scheduleHdrId: null
    };
  },
  mounted() {
    this.eventBus.$on('summaryDetail', summaryDetail => {
      this.notificationSummaryDetail = summaryDetail;
      this.selectedStatus = summaryDetail.status;
      this.eventId = summaryDetail.eventId;
      this.searchForm.eventId = summaryDetail.eventId;
      this.getNotificationStatus(summaryDetail);
    });
  },
  methods: {
    getNotificationStatus() {
      const payload = {
        status: this.selectedStatus,
        eventId: this.searchForm.eventId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getNotificationDetailByStatus', payload)
        .then(response => {
          this.loader = false;
          this.notificationDetailsList = [];
          this.notificationFields = [];
          if (response.status === 200 && response.data.data.data.length > 0) {
            const columnsField = response.data.data.columns;
            columnsField.forEach(index => {
              this.notificationFields.push({
                key: index.name,
                label: index.display_name,
                class: index.display ? '' : 'd-none'
              });
            });
            if (this.selectedStatus === 'INPROCESS' && this.searchForm.eventId != '32' && this.searchForm.eventId != '34') {
              this.notificationFields.unshift({
                key: 'selectbox',
                label: 'Select All',
                stickyColumn: true,
                variant: 'primary'
              });
            }
            const newArr = response.data.data.data.map(data => {
              data.selectbox = false;
              return data;
            });
            this.notificationDetailsList = newArr;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    search() {
      this.getNotificationStatus(this.selectedStatus);
    },
    clear() {
      this.searchForm.eventName = null;
      this.searchForm.eventId = null;
      this.searchForm.fromDate = null;
      this.searchForm.endDate = null;
    },
    selectedworkflowEvent(item) {
      this.eventName = item.event_name;
      this.searchForm.eventName = item.event_name;
      this.searchForm.eventId = item.event_id;
      this.showEventsModal = false;
    },
    showHideEventModal(flag) {
      this.showEventsModal = flag;
    },
    rowSelected(item) {
      this.wf_inprocess_dtl_id = item.wf_inprocess_dtl_id;
      const eventIdFromNotification = item.wf_event_id;
      this.eventTimeout = setTimeout(() => {
        this.eventBus.$emit('eventIdFromNotification', eventIdFromNotification);
      }, 0);
      this.eventDetailByName = item.wf_event_name;
      if (this.eventDetailByName === 'NOTE FOR APPROVAL') {
        this.getWfDetail(item.nfa_hdr_id);
        this.wfHeaderId = item.nfa_hdr_id;
      } else if (this.eventDetailByName === 'CN_APPLY_EVENT') {
        this.wfHeaderId = item.cn_hdr_id;
        this.getCreditNoteDetail(item);
      } else if (this.eventDetailByName === 'MASTER LEASE AGMT') {
        this.wfHeaderId = item.lease_master_agreement_hdr_id;
        this.getLeaseMasterAgrDetails(item.lease_master_agreement_hdr_id);
      } else if (this.eventDetailByName === 'TENANT LEASE AGMT') {
        this.wfHeaderId = item.lease_tenant_agreement_hdr_id;
        this.getLeaseTenantAgrDetails(item.lease_tenant_agreement_hdr_id);
      } else if (this.eventDetailByName === 'INVESTOR LEASE AGMT') {
        this.wfHeaderId = item.lease_investor_agreement_hdr_id;
        this.getLeaseInvestorAgrDetails(item.lease_investor_agreement_hdr_id);
      } else if (this.eventDetailByName === 'TENANT LEASE SCH') {
        this.scheduleHdrId = item.lease_tenant_schedule_hdr_id;
        this.hideIntWorkflow(true);
      } else if (this.eventDetailByName === 'INVESTOR LEASE SCH') {
        this.scheduleHdrId = item.lease_investor_schedule_hdr_id;
        this.hideIntWorkflow(true);
      } else if (this.eventDetailByName === 'Purchase Requisition Entry Form') {
        this.wfPurReqDetails = item.requisition_header_id;
        this.hideIntWorkflow(true);
      } else if (this.eventDetailByName === 'Add Provision'){
        this.wfProvisionDetails = item.prov_hdr_id;
        // this.hideIntWorkflow(true);
      }
    },
    getWfDetail(nfaHdrId) {
      this.wfHeaderId = nfaHdrId;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getWfDetailsById', nfaHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.selectedGridData = response.data.data;
            this.hideIntWorkflow(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCreditNoteDetail(item) {
      const payload = {
        _page: 0,
        _limit: 10,
        cn_hdr_id: item.cn_hdr_id,
        ou_id: item.erp_org_id,
        le_id: item.erp_le_id
      };
      this.loader = true;
      this.$store
        .dispatch('oracleApi/getSavedInvoiceList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.selectedGridData = response.data.data.page;
            this.hideIntWorkflow(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLeaseMasterAgrDetails(leaseMasterHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leases/getMasterAgrmentDetails', leaseMasterHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.leaseHdrDetails = response.data.data;
            this.hideIntWorkflow(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLeaseTenantAgrDetails(leaseHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leases/getTenantLeaseDetails', leaseHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.leaseHdrDetails = response.data.data;
            this.hideIntWorkflow(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLeaseInvestorAgrDetails(leaseInvestorHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseInvestorDetails', leaseInvestorHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.leaseHdrDetails = response.data.data;
            this.hideIntWorkflow(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    hideIntWorkflow(flag) {
      this.previewFlag = true;
      this.showAddIntWorkflow = flag;
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    selectAllBoxChecked(flag) {
      if (!flag) {
        this.selectedCheckBoxIndexArray = [];
      }
    },
    selectBoxChecked(flag, index) {
      if (flag) {
        this.selectedCheckBoxIndexArray.push(index);
      } else {
        this.selectedCheckBoxIndexArray.splice(index, 1);
      }
      this.isCheckBoxChecked();
    },
    checkUncheckAll() {
      this.notificationDetailsList = this.notificationDetailsList.map(data => {
        data.selectbox = this.selectAllCheckBox;
        return data;
      });
      this.isCheckBoxChecked();
    },
    isCheckBoxChecked() {
      this.isChecked =
        this.notificationDetailsList.filter(data => data.selectbox).length > 0;
    },
    changeStatus(status) {
      this.loader = true;
      const filterData = this.notificationDetailsList.filter(
        data => data.selectbox
      );
      const payload = filterData.map(data => {
        return {
          event_id: this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: data.wf_inprocess_dtl_id,
          temp_approved_amount: data.transactional_currency_amount_formated
            ? parseFloat(
              data.transactional_currency_amount_formated.replace(/,/g, '')
            )
            : 0
        };
      });
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          this.getNotificationStatus(this.notificationSummaryDetail);
          if (response.status === 200) {
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideConsult() { }
  },
  beforeDestroy() {
    this.eventBus.$off('summaryDetail');
    clearTimeout(this.eventTimeout);
  }
};
